const SKIP_LOADER_FLAG = 'skip_transformation'

const watermarkPartial = (h: number, w: number) => `c_fit,h_${h},l_watermark_2_m6kngk,w_${w}`
const resizePartial = (h: number, w: number, q: number | string) => `c_fill,h_${h},q_${q},w_${w}`

const urlParts = (url: string) => {
    const parts = url.split('/')
    const imageId = parts.pop()
    const imageFolder = parts.pop()
    if (parts.slice(-1)[0].includes('v')) {
        parts.pop()
    }
    return { parts, imageId, imageFolder }
}

/*
 * Strips the cloudinary url source to the image path
 * The domain is not necessary when use <CldImage> component
 */
export const toCldPublicId = (url: string) => url.split('/image/upload/').slice(1).join('')

export const replaceExtension = (extension: string) => (url: string) => {
    const parts = url.split('.')
    parts.pop()
    return `${parts.join('.')}.${extension}`
}

export const toWebp = replaceExtension('webp')

export function resize(url: string | null, h: number, w: number, q: string = 'auto'): string {
    if (url === null) {
        return ''
    }
    try {
        const { parts, imageId, imageFolder } = urlParts(url)
        return `${parts.join('/')}/${resizePartial(h, w, q)}/${imageFolder}/${imageId}?${SKIP_LOADER_FLAG}`
    } catch (e) {
        return ''
    }
}

export function withWatermark(url: string, h: number, w: number): string {
    const { parts, imageId, imageFolder } = urlParts(url)
    return `${parts.join('/')}/${watermarkPartial(h, w)}/${imageFolder}/${imageId}`
}
